import _functionName from "../internals/function-name";
import _defineBuiltIn from "../internals/define-built-in";
import _anObject from "../internals/an-object";
import _toString from "../internals/to-string";
import _fails from "../internals/fails";
import _regexpGetFlags from "../internals/regexp-get-flags";
var PROPER_FUNCTION_NAME = _functionName.PROPER;
var defineBuiltIn = _defineBuiltIn;
var anObject = _anObject;
var $toString = _toString;
var fails = _fails;
var getRegExpFlags = _regexpGetFlags;
var TO_STRING = "toString";
var RegExpPrototype = RegExp.prototype;
var nativeToString = RegExpPrototype[TO_STRING];
var NOT_GENERIC = fails(function () {
  return nativeToString.call({
    source: "a",
    flags: "b"
  }) != "/a/b";
}); // FF44- RegExp#toString has a wrong name

var INCORRECT_NAME = PROPER_FUNCTION_NAME && nativeToString.name != TO_STRING; // `RegExp.prototype.toString` method
// https://tc39.es/ecma262/#sec-regexp.prototype.tostring

if (NOT_GENERIC || INCORRECT_NAME) {
  defineBuiltIn(RegExp.prototype, TO_STRING, function toString() {
    var R = anObject(this);
    var pattern = $toString(R.source);
    var flags = $toString(getRegExpFlags(R));
    return "/" + pattern + "/" + flags;
  }, {
    unsafe: true
  });
}

export default {};